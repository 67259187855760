.App {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width:100%;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  pointer-events: none;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width:100%;
  font-size: calc(10px + 2vmin);
  color: white;
}

.multi-header {
  padding: 5% 0;
}

.multi-header h1, .multi-header h4 {
  margin: 0;
  padding: 0;
}

p {
  font-size: .8em;
}

h1, h3, h2, h4{
 text-align: center;
}

h4 {
  font-size: .6em;
}



h1 {
  color: #5d72af;
}

h2, h4 {
  color: #ffffff;
}

section.vision-section {
  margin: 10px 0 0 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

hr {
  width: 60%;
}

.vision-section p{
  width: 90%;
  margin-bottom: 10%;
  line-height: 1.8em;
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}